

















































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  inject,
  onMounted,
  Ref,
  ref,
  watch
} from '@vue/composition-api';

import { parseISO } from 'date-fns';

import { Operation, OperationInput } from '@/models/operation.model';

import { useActions, useGetters } from 'vuex-composition-helpers';
import {
  CREATE_OR_SAVE_OPERATION,
  FETCH_ASSET,
  GET_OPERATIONS,
  REMOVE_IMAGE,
  UPLOAD_IMAGE
} from '@/store/operation.actions';
import { Emitter } from 'mitt';

import {
  CREATE_OR_SAVE_ORGANISATION,
  GET_ORGANISATIONS
} from '@/store/organisation.actions';
import { CREATE_OR_SAVE_CAR, GET_CARS } from '@/store/car.actions';

import { VForm } from '@/types/form.type';
import { SelectEntry } from '@/types/select-entry.type';
import {
  CREATE_OR_SAVE_OPERATION_TYPE,
  GET_OPERATION_TYPES
} from '@/store/operation-type.actions';
import { OperationTypeEntry } from '@/models/operation-type.model';
import {
  CREATE_OR_SAVE_LOCATION,
  GET_LOCATIONS
} from '@/store/location.actions';
import { LocationEntry } from '@/models/location.model';
import { CarEntry } from '@/models/car.model';
import { OrganisationEntry } from '@/models/organisation.model';
import { Entry } from '@/models/entry.model';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import {
  clearAssets as clsAssets,
  clearPreviewAsset as clsPreviewAsset,
  clearNewAssets as clsNewAssets,
  fetchPreviewAssetForImageEntry,
  updateEntryEditAssets,
  url,
  singleUrl,
  sendEntryForm,
  getTimeString,
  getDateString,
  imageEntryWatcher
} from '@/helpers/image-entry-helpers';
import { AssetType } from '@/types/asset.type';
import { dateRules, timeRules, shortReportRules } from '@/helpers/common-rules';

export default defineComponent({
  name: 'OperationForm',
  props: {
    id: {
      type: String
    }
  },
  components: {
    VuePdfEmbed
  },
  setup: (props, ctx) => {
    const getters = useGetters([
      GET_OPERATIONS,
      GET_CARS,
      GET_ORGANISATIONS,
      GET_OPERATION_TYPES,
      GET_LOCATIONS
    ]);

    // get operation
    const operations = getters[GET_OPERATIONS];

    const id = props.id;
    const operation = (operations.value as Operation[]).find(
      op => op._id === id
    );

    const valid = ref(false);
    const form = ref();

    const shortReport = ref(operation?.kurzbericht ?? '');

    // Einsatzort
    const locations = getters[GET_LOCATIONS];
    const locationNames = computed(() => {
      return (locations.value as LocationEntry[]).map(t => t.name);
    });
    const location = ref(operation?.ort ?? '');

    // Einsatzarten
    const operationTypes = getters[GET_OPERATION_TYPES];
    const operationTypeNames = computed(() => {
      return (operationTypes.value as OperationTypeEntry[]).map(t => t.name);
    });
    const operationType: Ref<string> = ref(operation?.einsatzart ?? '');

    // Datum
    const dateMenu = ref(false);
    const timestampInput = operation?.zeitstempel
      ? parseISO(operation?.zeitstempel)
      : null;

    const date = ref(getDateString(timestampInput));
    const localeDate = computed(() => {
      return new Date(date.value).toLocaleDateString();
    });

    const time: Ref<string> = ref(getTimeString(timestampInput));
    const timeMenu = ref(false);

    // Fahrzeuge
    const carsEntries = getters[GET_CARS];
    const cars = computed(() => {
      return carsEntries.value;
    });
    const selectedCars = ref(
      operation?.fahrzeuge ? [...operation?.fahrzeuge] : []
    );

    // Einheiten
    const organisationEntries = getters[GET_ORGANISATIONS];
    const organisations = computed(() => {
      return organisationEntries.value;
    });
    const selectedOrganisations = ref(
      operation?.einheiten ? [...operation?.einheiten] : []
    );
    // const pressUrl1 = ref(operation?.presseUrl1 ?? '');
    // const pressUrl2 = ref(operation?.presseUrl2 ?? '');
    // const pressUrl3 = ref(operation?.presseUrl3 ?? '');
    const report = ref(operation?.bericht ?? '');

    const allAlarmTypes: SelectEntry[] = [
      {
        text: 'Keine Angabe',
        value: ''
      },
      {
        text: 'Funkmelder',
        value: 'Funkmelder'
      },
      {
        text: 'Telefon',
        value: 'Telefon'
      },
      {
        text: 'O-Amt',
        value: 'O-Amt'
      },
      {
        text: 'direkt',
        value: 'direkt'
      }
    ];
    const alarmType = ref(
      operation?.alarmierungsart
        ? allAlarmTypes.find(a => a.value === operation.alarmierungsart)
            ?.value ?? ''
        : ''
    );

    const imgAssets = ref([] as File[]);
    const imgAssetUrls = ref([] as string[]);
    const imageUrl = () => {
      url(imgAssets, imgAssetUrls);
    };

    const pdfAssets = ref([] as File[]);
    const pdfAssetUrls = ref([] as string[]);
    const pdfUrl = () => {
      url(pdfAssets, pdfAssetUrls);
    };

    const previewAsset = ref();
    const previewAssetUrl = ref('');
    const previewAssetToType = ref({} as { [key: string]: AssetType });
    const previewUrl = () => {
      singleUrl(previewAsset, previewAssetUrl, previewAssetToType);
    };

    const actions = useActions([
      UPLOAD_IMAGE,
      CREATE_OR_SAVE_OPERATION,
      FETCH_ASSET,
      REMOVE_IMAGE,
      CREATE_OR_SAVE_OPERATION_TYPE,
      CREATE_OR_SAVE_LOCATION,
      CREATE_OR_SAVE_CAR,
      CREATE_OR_SAVE_ORGANISATION
    ]);

    const isEditing = ref(!!props.id);
    const confirmImgDialog = ref(false);
    const confirmPdfDialog = ref(false);
    const confirmEditAssetDialog = ref(false);

    const isPreviewEditingFirst = ref(true);
    const confirmPreviewDialog = ref(false);
    const useInputPreviewAsset = ref(false);

    const removeImageAction = actions[REMOVE_IMAGE];
    const createSaveOpAction = actions[CREATE_OR_SAVE_OPERATION];
    const $emitter = inject('$emitter') as Emitter;

    const editAssets = ref([] as SelectEntry[]);
    const selectedEditAssets = ref([] as string[]);
    const clearAssets = async () => {
      clsAssets(
        selectedEditAssets.value.map(a => ({ _id: a })),
        confirmEditAssetDialog,
        operation,
        removeImageAction,
        createSaveOpAction,
        $emitter
      );
    };

    const clearNewPdfAssets = async () => {
      clsNewAssets(pdfAssetUrls, pdfAssets, confirmImgDialog);
    };

    const clearNewImgAssets = async () => {
      clsNewAssets(imgAssetUrls, imgAssets, confirmImgDialog);
    };

    const clearPreviewAsset = async () => {
      previewAssetToType.value = {};
      clsPreviewAsset(
        isEditing,
        previewAssetUrl,
        previewAsset,
        confirmPreviewDialog,
        isPreviewEditingFirst,
        useInputPreviewAsset,
        operation,
        removeImageAction,
        createSaveOpAction,
        $emitter
      );
    };

    const fetchAssetAction = actions[FETCH_ASSET];
    const updateEditAssets = async (op: Operation) => {
      await updateEntryEditAssets(
        op,
        editAssets,
        fetchAssetAction,
        imgAssetUrls,
        pdfAssetUrls
      );
    };

    onMounted(async () => {
      if (!operation) {
        return;
      }

      useInputPreviewAsset.value =
        !!operation.vorschaubild && operation.vorschaubild.length > 0;

      if (Array.isArray(operation.bilder)) {
        updateEditAssets(operation);
      }

      if (useInputPreviewAsset.value) {
        fetchPreviewAssetForImageEntry(
          fetchAssetAction,
          operation.vorschaubild as string,
          previewAssetUrl,
          previewAsset,
          previewAssetToType
        );
      }
    });

    // update edit assets after upload of new images
    watch(operations, (ops, _oldOps) => {
      imageEntryWatcher(
        ops,
        editAssets,
        id,
        fetchAssetAction,
        imgAssetUrls,
        pdfAssetUrls
      );
    });

    // Einsatzart
    const createSaveOperationTypeAction =
      actions[CREATE_OR_SAVE_OPERATION_TYPE];
    const updateOperationType = async (opType: string) => {
      if (opType?.length === 0) {
        return;
      }
      if (operationTypeNames.value.some(n => n === opType)) {
        return;
      }
      await createSaveOperationTypeAction({
        name: opType
      } as OperationTypeEntry);
    };

    // Einsatzort
    const createSaveLocationAction = actions[CREATE_OR_SAVE_LOCATION];
    const updateLocation = async (location: string) => {
      if (location?.length === 0) {
        return;
      }
      if (locationNames.value.some(l => l === location)) {
        return;
      }
      await createSaveLocationAction({
        name: location
      } as LocationEntry);
    };

    // Fahrzeuge
    const filterNewEntry = (
      selectedEntry: Entry | string,
      allEntries: Entry[]
    ) =>
      (typeof selectedEntry === 'string' &&
        !allEntries.some(ce => ce.name === selectedEntry)) ||
      (typeof selectedEntry === 'object' &&
        !allEntries.some(ce => ce.name === selectedEntry.name));

    const partitionEntries = (
      selectedEntries: (Entry | string)[],
      allEntries: Entry[]
    ) => {
      return selectedEntries.reduce(
        (entryDict, entry) => {
          // neue Einträge können Strings sein, alte nur Entry Objekte
          if (filterNewEntry(entry, allEntries)) {
            if (typeof entry === 'string') {
              entryDict.newEntries.push({
                name: entry
              });
            } else {
              entryDict.newEntries.push(entry);
            }
          } else {
            entryDict.oldEntries.push(entry);
          }

          return entryDict;
        },
        {
          newEntries: [] as (Entry | string)[],
          oldEntries: [] as (Entry | string)[]
        }
      );
    };

    const createSaveCarAction = actions[CREATE_OR_SAVE_CAR];
    const updateCars = async (_selectedCars: CarEntry[]) => {
      const partitionedCars = partitionEntries(_selectedCars, cars.value);

      if (partitionedCars.newEntries.length <= 0) {
        return partitionedCars.oldEntries as CarEntry[];
      }

      const savedNewEntriesResp = await createSaveCarAction(
        partitionedCars.newEntries
      );
      if (savedNewEntriesResp == null) {
        return;
      }

      const savedNewEntries = Array.isArray(savedNewEntriesResp)
        ? [...savedNewEntriesResp]
        : [savedNewEntriesResp];

      return [
        ...savedNewEntries, // IDs bestimmen
        ...partitionedCars.oldEntries
      ] as CarEntry[];
    };

    // Einheiten
    const createSaveOrganisationAction = actions[CREATE_OR_SAVE_ORGANISATION];
    const updateOrganisations = async (_selectedOrgs: OrganisationEntry[]) => {
      const partitionedOrgs = partitionEntries(
        _selectedOrgs,
        organisations.value
      );

      if (partitionedOrgs.newEntries.length <= 0) {
        return partitionedOrgs.oldEntries as OrganisationEntry[];
      }

      const savedNewEntriesResp = await createSaveOrganisationAction(
        partitionedOrgs.newEntries
      );
      if (savedNewEntriesResp == null) {
        return;
      }

      const savedNewEntries = Array.isArray(savedNewEntriesResp)
        ? [...savedNewEntriesResp]
        : [savedNewEntriesResp];
      return [
        ...savedNewEntries, // IDs bestimmen
        ...partitionedOrgs.oldEntries
      ] as OrganisationEntry[];
    };

    const uploadImageAction = actions[UPLOAD_IMAGE];
    const sendForm = async () => {
      let saveCars: CarEntry[] = [];
      let saveOrgs: OrganisationEntry[] = [];

      const res = await sendEntryForm(
        form,
        imgAssets,
        pdfAssets,
        uploadImageAction,
        useInputPreviewAsset,
        previewAsset,
        time,
        operation as Operation,
        isEditing,
        date,
        createSaveOpAction,
        (o: OperationInput) => {
          o.ort = location.value;
          o.kurzbericht = shortReport.value;
          o.einsatzart = operationType.value;
          o.fahrzeuge = saveCars?.map(c => ({
            _id: c._id as string,
            link: 'fahrzeug'
          }));
          o.einheiten = saveOrgs?.map(o => ({
            _id: o._id as string,
            link: 'einheit'
          }));
          o.bericht = report.value;
          // o.presseUrl1 = pressUrl1.value;
          // o.presseUrl2 = pressUrl2.value;
          // o.presseUrl3 = pressUrl3.value;
          o.alarmierungsart = alarmType.value;
        },
        async () => {
          saveCars = (await updateCars(selectedCars.value)) as CarEntry[];
          saveOrgs = (await updateOrganisations(
            selectedOrganisations.value
          )) as OrganisationEntry[];

          await updateOperationType(operationType.value);
          await updateLocation(location.value);
        }
      );

      if (res) {
        // location.reload();
        setTimeout(() => ctx.root.$router.go(0), 500);

        // if (!isEditing.value && res?._id?.length > 0) {
        //   const vform = form?.value as VForm;
        //   vform?.reset();

        //   // Assets aufräumen
        //   clearAssetsUrls(
        //     imgAssetUrls,
        //     pdfAssetUrls,
        //     previewAssetUrl,
        //     imgAssets,
        //     pdfAssets,
        //     previewAsset,
        //     previewAssetToType
        //   );
        // }
      }
    };

    const operationTypeRules = ref([
      (v: string) => (!!v && v.length > 0) || 'Bitte Einsatzart angeben'
    ]);
    const locationRules = ref([
      (v: string) => (!!v && v.length > 0) || 'Bitte Einsatzort angeben'
    ]);
    const carsRules = ref([
      (v: string) => (!!v && v.length > 0) || 'Bitte Fahrzeuge angeben'
    ]);
    const organisationsRules = ref([
      (v: string) => (!!v && v.length > 0) || 'Bitte Einheiten angeben'
    ]);

    return {
      form,
      valid,
      locationNames,
      location,
      shortReport,
      operationTypeNames,
      operationType,
      dateMenu,
      date,
      localeDate,
      time,
      timeMenu,
      cars,
      selectedCars,
      organisations,
      selectedOrganisations,
      alarmType,
      allAlarmTypes,
      report,
      imgAssets,
      imgAssetUrls,
      imageUrl,
      pdfAssets,
      pdfAssetUrls,
      pdfUrl,
      clearAssets,
      confirmImgDialog,
      confirmPdfDialog,
      confirmEditAssetDialog,
      isEditing,
      editAssets,
      selectedEditAssets,
      clearNewPdfAssets,
      clearNewImgAssets,
      useInputPreviewAsset,
      previewAsset,
      previewAssetUrl,
      previewAssetToType,
      previewUrl,
      clearPreviewAsset,
      confirmPreviewDialog,
      isPreviewEditingFirst,

      sendForm,
      operationTypeRules,
      locationRules,
      dateRules,
      timeRules,
      carsRules,
      organisationsRules,
      shortReportRules
    };
  }
});
